<template>
  <div>
    <v-data-table
      :headers="getHeaders()"
      :items="tableData"
      disable-pagination
      fixed-header
      :options.sync="options"
      calculate-widths
      height="calc(100vh - 254px)"
      hide-default-footer
      :server-items-length="1"
      disable-filtering
      class="elevation-1"
    >
      <template v-slot:item.type="{ item }">
        {{ item.type == "M" ? "Giữa kì" : "Cuối kì" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          class="ml-2"
          x-small
          @click="showForm(item.id)"
          fab
          dark
          color="blue"
        >
          <v-icon dark>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          x-small
          @click="showImage(item.id)"
          fab
          dark
          color="blue"
        >
          <v-icon dark>mdi-image</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="$emit('handle-reset')">Refresh</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card class="mt-0 pt-1" v-if="currentForm" style="width:100%">
        <v-card-title class="py-2">
          <span style="float:left">Mã đề: {{ currentForm.exam.code }}</span>
          <span style="float:right">Số câu đúng: {{ countCorrectAns }}</span>
        </v-card-title>
        <v-card-text
          class="py-0"
          style="height: calc(100vh - 255px);overflow-y: auto"
        >
          <div v-for="answer in currentForm.answers" :key="answer.id">
            <h4 class="mb-2">
              Câu {{ answer.question.order }} - Level
              {{ answer.question.originalQuestion.level }}
            </h4>
            <img :src="answer.question.originalQuestion.content" alt="" />
            <div v-for="choice in answer.question.choices" :key="choice.id">
              <p
                :style="{
                  'font-weight': answer.choices
                    .map((item) => item.id)
                    .includes(choice.id)
                    ? 'bold'
                    : 'unset',
                  fontSize: '14px',
                  color: choice.originalChoice.isCorrect ? 'green' : 'unset',
                }"
                class="my-2"
              >
                Phương án {{ choice.order }}:
                <span v-if="choice.originalChoice.type === 'text'">{{
                  choice.originalChoice.content
                }}</span>
              </p>
              <img
                :src="choice.originalChoice.image"
                v-if="choice.originalChoice.image"
                alt=""
              />
            </div>
            <v-divider class="my-2"></v-divider>
          </div>
        </v-card-text>
        <v-card-actions class="pr-5">
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$refs.html2Pdf.generatePdf()"
          >
            PDF <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" max-width="600px">
      <v-card class="mt-0 pt-1" style="width:100%">
        <v-card-title class="py-2">
          Ảnh
        </v-card-title>
        <v-card-text>
          <v-img width="100%" :src="`/api/forms/${image}/image`" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <Form ref="html2Pdf" :form="currentForm" />
  </div>
</template>
<script>
import { destroy } from "@/api/officer";
import { getForm } from "@/api/exam";
import dataTableMixin from "@/mixins/crud/data-table";
import Form from "@/components/pdf/Form";
export default {
  mixins: [dataTableMixin(destroy)],
  components: { Form },
  data() {
    return {
      currentForm: null,
      dialog: false,
      dialog1: false,
      image: null,
    };
  },
  computed: {
    headers() {
      return [
        { text: "MSSV", value: "studentCode" },
        { text: "Tên", value: "studentName" },
        { text: "Mã lóp", value: "classId" },
        { text: "Nhóm", value: "studyGroupName" },

        {
          text: "Hành động",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ];
    },
    countCorrectAns() {
      if (this.currentForm)
        return this.currentForm.answers.filter((item) => item.isCorrect).length;
      return 0;
    },
  },

  methods: {
    async showForm(id) {
      const { data } = await getForm(id);
      for (const ans of data.answers) {
        const correctChoices = ans.question.choices
          .filter((c) => c.originalChoice.isCorrect)
          .map((item) => item.id)
          .sort();
        const choices = ans.choices.map((item) => item.id).sort();

        ans.isCorrect =
          JSON.stringify(correctChoices) === JSON.stringify(choices);
      }
      this.currentForm = data;
      this.dialog = true;
    },
    showImage(image) {
      this.image = image;
      this.dialog1 = true;
    },
  },
};
</script>

<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    filename="hehehe"
    :paginate-elements-by-height="1100"
    :pdf-quality="1"
    pdf-format="a4"
    pdf-orientation="portrait"
    :manual-pagination="false"
    ref="html2Pdf"
    pdf-content-width="750px"
    :html-to-pdf-options="{ margin: [5, 5, 5, 5] }"
  >
    <section v-if="ready" slot="pdf-content">
      <p>
        (*) Phương án được gạch chân là đáp án đúng, phương án có dấu &#10003;
        là đáp án của sinh viên
      </p>
      <h1>{{ form.exam.name }}</h1>
      <h1>{{ form.studentName }} - {{ form.studentCode }}</h1>
      <h1>
        <span> Mã đề {{ form.exam.code }} </span>
        <span style="float: right"
          >Số câu đúng:{{ countCorrectAns }}/{{ form.answers.length }}</span
        >
      </h1>

      <div v-for="answer in form.answers" :key="answer.id">
        <h4 class="mb-2">
          Câu {{ answer.question.order }} - Level
          {{ answer.question.originalQuestion.level }}
        </h4>
        <img :src="answer.question.resizedContent" alt="" />
        <div
          v-for="choice in answer.question.choices"
          style="margin-bottom:10px"
          :key="choice.id"
        >
          <p
            :style="{
              'text-decoration': choice.originalChoice.isCorrect
                ? 'underline'
                : 'none',
              fontSize: '14px',
            }"
            class="my-2"
          >
            Phương án {{ choice.order }}:
            <span v-if="choice.originalChoice.type === 'text'">{{
              choice.originalChoice.content
            }}</span>
            <span
              v-if="answer.choices.map((item) => item.id).includes(choice.id)"
            >
              &#10003;</span
            >
          </p>
          <img
            :src="choice.originalChoice.image"
            v-if="choice.originalChoice.image"
            alt=""
          />
        </div>
      </div>
      <!-- <div v-for="question in exam.questions" :key="question.id">
        <h4 class="mb-2">
          Câu {{ question.order }} - Level
          {{ question.originalQuestion.level }}
        </h4>
        <img :src="question.resizedContent" alt="" />
        <div
          style="margin-bottom:10px"
          v-for="choice in question.choices"
          :key="choice.id"
        >
          <p
            :style="{
              color: choice.originalChoice.isCorrect ? 'red' : 'unset',
              fontWeight: choice.originalChoice.isCorrect ? 'bold' : 'unset',
              fontSize: '14px',
              textDecoration: choice.originalChoice.isCorrect
                ? 'underline'
                : 'unset',
            }"
            class="my-2"
          >
            Phương án {{ choice.order }}:
            <span v-if="choice.originalChoice.type === 'text'">{{
              choice.originalChoice.content
            }}</span>
          </p>
          <img
            :src="choice.originalChoice.image"
            v-if="choice.originalChoice.image"
            alt=""
          />
        </div>
      </div> -->
    </section>
  </vue-html2pdf>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
function base64Resize(sourceBase64, scale, callBack) {
  var img = document.createElement("img");
  img.setAttribute("src", sourceBase64);
  return new Promise(function(resolve, reject) {
    img.onload = () => {
      var canvas = document.createElement("canvas");
      const _scale = img.width > 750 ? 750 / img.width : 1;
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", 1);
      resolve(newBase64);
    };
  });
}
export default {
  components: { VueHtml2pdf },
  props: ["form"],
  methods: {
    generatePdf() {
      if (!this.ready) {
        return this.$snackbar(
          "PDF chưa sẵn sàng, vui lòng chờ trong giây lát",
          "error"
        );
      }
      this.$refs.html2Pdf.generatePdf();
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    countCorrectAns() {
      if (this.form)
        return this.form.answers.filter((item) => item.isCorrect).length;
      return 0;
    },
  },
  watch: {
    async form(form) {
      this.ready = false;
      for (const a of form.answers) {
        console.log(a);
        const resizedContent = await base64Resize(
          a.question.originalQuestion.content
        );
        a.question.resizedContent = resizedContent;
      }
      this.ready = true;
    },
  },
};
</script>

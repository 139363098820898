<template>
  <v-row>
    <v-col md="8" cols="12" class="pb-0">
      <v-row>
        <v-col cols="12" md="6" lg="4" class="pb-0">
          <v-select
            :items="options.examinations"
            outlined
            dense
            label="Kíp thi"
            item-text="name"
            item-value="id"
            @change="getSubjects"
            v-model="params.examinationId"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="pb-0">
          <v-select
            :items="subjects"
            outlined
            dense
            label="Học phần"
            item-text="fullName"
            item-value="id"
            v-model="params.subjectId"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="pb-0">
          <v-text-field
            outlined
            dense
            label="Tên hoặc MSSV"
            v-model="params.search"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" style="margin-top: 12px">
      <v-btn
        class="float-md-right mr-md-0 mb-0"
        @click="$emit('handle-search')"
        color="primary"
        style="height: 40px"
        medium
        >Tìm kiếm</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["params", "options"],
  data() {
    return {
      subjects: []
    };
  },
  methods: {
    getSubjects(val) {
      const examination = this.options.examinations.find(
        item => item.id === val
      );
      this.subjects = examination.subjects;
    }
  }
};
</script>
<style lang=""></style>

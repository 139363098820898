<template>
  <v-container
    style="height: calc(100vh - 60px); overflow-y: auto"
    fluid
    class="py-0"
  >
    <v-row>
      <v-col class="py-0" cols="12">
        <Search
          :params="params"
          @handle-search="getData(1)"
          @handle-reset="reset"
          :options="options"
        />
      </v-col>
      <v-col class="py-0" cols="12">
        <DataTable
          :form="form"
          :table-data="tableData"
          @handle-edit="showDialogForm('edit', $event)"
          @handle-create="showDialogForm('create')"
          @deleted="getData()"
          :pagination="pagination"
          @sorted="handleSort"
          @handle-export="exportData"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <Pagination
          :length="pagination.lastPage"
          :params="params"
          @handle-change-page="getData"
          @handle-change-per-page="getData(1)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from "./components/DataTable";
import Search from "./components/Search";
import Pagination from "@/components/Pagination";
import { getForms } from "@/api/exam";
import indexMixin from "@/mixins/crud/index";
import { getSubjects, getExaminations } from "@/api/original-exam";
export default {
  mixins: [
    indexMixin(getForms, {
      subjects: {
        func: getSubjects,
        params: {}
      },
      examinations: {
        func: getExaminations,
        params: {}
      }
    })
  ],
  components: { DataTable, Search, Pagination },
  data() {
    return {
      showDialog: false,
      editing: false,
      defaultParams: {
        search: "",
        sortBy: "studentCode",
        subjectId: "",
        examinationId: "",
        page: 1,
        perPage: 20
      },
      form: {
        id: undefined
      }
    };
  }
};
</script>

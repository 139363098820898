var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders(),"items":_vm.tableData,"disable-pagination":"","fixed-header":"","options":_vm.options,"calculate-widths":"","height":"calc(100vh - 254px)","hide-default-footer":"","server-items-length":1,"disable-filtering":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type == "M" ? "Giữa kì" : "Cuối kì")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","fab":"","dark":"","color":"blue"},on:{"click":function($event){return _vm.showForm(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","fab":"","dark":"","color":"blue"},on:{"click":function($event){return _vm.showImage(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-image")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('handle-reset')}}},[_vm._v("Refresh")])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.currentForm)?_c('v-card',{staticClass:"mt-0 pt-1",staticStyle:{"width":"100%"}},[_c('v-card-title',{staticClass:"py-2"},[_c('span',{staticStyle:{"float":"left"}},[_vm._v("Mã đề: "+_vm._s(_vm.currentForm.exam.code))]),_c('span',{staticStyle:{"float":"right"}},[_vm._v("Số câu đúng: "+_vm._s(_vm.countCorrectAns))])]),_c('v-card-text',{staticClass:"py-0",staticStyle:{"height":"calc(100vh - 255px)","overflow-y":"auto"}},_vm._l((_vm.currentForm.answers),function(answer){return _c('div',{key:answer.id},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Câu "+_vm._s(answer.question.order)+" - Level "+_vm._s(answer.question.originalQuestion.level)+" ")]),_c('img',{attrs:{"src":answer.question.originalQuestion.content,"alt":""}}),_vm._l((answer.question.choices),function(choice){return _c('div',{key:choice.id},[_c('p',{staticClass:"my-2",style:({
                'font-weight': answer.choices
                  .map(function (item) { return item.id; })
                  .includes(choice.id)
                  ? 'bold'
                  : 'unset',
                fontSize: '14px',
                color: choice.originalChoice.isCorrect ? 'green' : 'unset',
              })},[_vm._v(" Phương án "+_vm._s(choice.order)+": "),(choice.originalChoice.type === 'text')?_c('span',[_vm._v(_vm._s(choice.originalChoice.content))]):_vm._e()]),(choice.originalChoice.image)?_c('img',{attrs:{"src":choice.originalChoice.image,"alt":""}}):_vm._e()])}),_c('v-divider',{staticClass:"my-2"})],2)}),0),_c('v-card-actions',{staticClass:"pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$refs.html2Pdf.generatePdf()}}},[_vm._v(" PDF "),_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Đóng ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog1),callback:function ($$v) {_vm.dialog1=$$v},expression:"dialog1"}},[_c('v-card',{staticClass:"mt-0 pt-1",staticStyle:{"width":"100%"}},[_c('v-card-title',{staticClass:"py-2"},[_vm._v(" Ảnh ")]),_c('v-card-text',[_c('v-img',{attrs:{"width":"100%","src":("/api/forms/" + _vm.image + "/image")}})],1)],1)],1),_c('Form',{ref:"html2Pdf",attrs:{"form":_vm.currentForm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }